import gql from 'graphql-tag';
import { ID } from '@cinuru/utils/types';
import client from '../apollo';

export const TicketDetails = gql`
	fragment TicketDetails on Ticket {
		id
		screening {
			id
			movie {
				id
				title
				poster
			}
			datetime
			cinema {
				name
			}
		}
		boughtAt
		status
		onlineTicketingId
		isFlatrateTicket
		qrCode
		auditoriumName
		seats {
			seatName
			rowName
		}
		scanned
		refundable
		cancelationLink
	}
`;

export type Ticket = {
	id: ID;
	screening: {
		id: ID;
		movie: {
			id: ID;
			title: string;
			poster: string;
		};
		datetime: string;
		cinema: {
			name: string;
		};
	};
	boughtAt: string;
	status: string;
	onlineTicketingId: string;
	isFlatrateTicket: boolean;
	qrCode: string;
	auditoriumName: string;
	seats: {
		seatName: string;
		rowName: string;
	}[];
	scanned: boolean;
	refundable: boolean;
	cancelationLink: string;
};

export const updateTicket = async ({
	id,
	scanned,
}: {
	id: ID;
	scanned?: boolean;
}): Promise<{ success: true; error: undefined } | { success: false; error?: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateTicket($id: ID!, $scanned: Boolean!) {
					updateTicket(id: $id, scanned: $scanned) {
						...TicketDetails
					}
				}
				${TicketDetails}
			`,
			variables: {
				id,
				scanned,
			},
			errorPolicy: 'all',
		});
		if (errors) {
			return { success: false, error: errors[0]?.message };
		} else return { success: true, error: undefined };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};

export const cancelTicket = async (
	ticketId: ID
): Promise<{ success: true; error: null } | { success: false; error?: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation CancelTicket($ticketId: ID!) {
					cancelTicket(id: $ticketId) {
						canceledTicket {
							id
							status
							screening {
								id
								movie {
									id
									title
								}
							}
						}
						currentUser {
							id
							name
						}
					}
				}
			`,
			variables: { ticketId },
			errorPolicy: 'all',
		});

		if (errors) {
			const errorMessage = errors[0]?.message;
			return { success: false, error: errorMessage };
		}

		return { success: true, error: null };
	} catch (e: any) {
		if (e.networkError) {
			return { success: false, error: 'NETWORK_ERROR' };
		}
		throw e;
	}
};

export const syncTicket = async (
	id: ID
): Promise<{ success: true; error: null } | { success: false; error?: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation SyncTicket($id: ID!) {
					syncTicket(id: $id) {
						...TicketDetails
					}
				}
				${TicketDetails}
			`,
			variables: { id },
			errorPolicy: 'all',
		});
		if (errors) {
			const errorMessage = errors[0]?.message;
			return { success: false, error: errorMessage };
		}
		return { success: true, error: null };
	} catch (e: any) {
		if (e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		else throw e;
	}
};
