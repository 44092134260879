import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';

export type SubscriptionStatistics = {
	numberOfUsers: number;
	numberOfActiveSubscriptions: number;
	numberOfSubscriptionTickets: number;
	numberOfNewSubscriptions: number;
	canceledSubscriptions: number;
	numberOfUsersPerTier: {
		tierName: string;
		numActiveSubscribers: number;
	}[];
	visitFrequency: {
		zero: number;
		one: number;
		two: number;
		three: number;
		four: number;
		five: number;
		sixToTen: number;
		elevenToTwenty: number;
		twentyPlus: number;
	};
	seenMovies: {
		numVisits: number;
		movie: {
			id: string;
			title: string;
			banner: string;
			poster: string;
			averageRating: number;
		};
	}[];
};

export const useSubscriptionStatistics = (
	from: string,
	to: string
): { data: SubscriptionStatistics; error?: ApolloError; loading: boolean } => {
	const { data, error, loading } = useQuery(
		gql`
			query SubscriptionStatictics($from: Date!, $to: Date!) {
				subscriptionsStatistics(from: $from, to: $to) {
					numberOfUsers
					numberOfActiveSubscriptions
					numberOfSubscriptionTickets
					numberOfNewSubscriptions
					canceledSubscriptions
					numberOfUsersPerTier {
						tierName
						numActiveSubscribers
					}
					visitFrequency {
						zero
						one
						two
						three
						four
						five
						sixToTen
						elevenToTwenty
						twentyPlus
					}
					seenMovies {
						numVisits
						movie {
							id
							title
							banner
							poster
							averageRating
						}
					}
				}
			}
		`,
		{
			variables: { from, to },
		}
	);
	return React.useMemo(() => ({ data: data?.subscriptionsStatistics, error, loading }), [
		data?.subscriptionsStatistics,
		error,
		loading,
	]);
};

export type PayoutInfo = {
	numOfSubscriptionTickets: number;
	numAdditionalTickets: number;
	cinema: {
		id: string;
		name: string;
		city: string;
	};
};

export const usePayoutInfo = (
	from: string,
	to: string
): { data: PayoutInfo[]; error?: ApolloError; loading: boolean } => {
	const { data, error, loading } = useQuery(
		gql`
			query PayoutInfo($from: Date!, $to: Date!) {
				payoutInfo(from: $from, to: $to) {
					numOfSubscriptionTickets
					numAdditionalTickets
					cinema {
						id
						name
						city
					}
				}
			}
		`,
		{
			variables: { from, to },
		}
	);
	return React.useMemo(() => ({ data: data?.payoutInfo, error, loading }), [
		data?.payoutInfo,
		error,
		loading,
	]);
};
