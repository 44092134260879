import React from 'react';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { addMonths, format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { Box } from '@mui/material';

import Table from '../../components/Table';
import SectionWrapper2 from '../../components/SectionWrapper2';
import Txt from '../../components/Txt';
import { usePayoutInfo } from '../../utils/statistics';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import LoadingBox from '../../components/LoadingBox';
import DatetimeRangeSelectForm from '../../components/DatetimeRangeSelectForm';
import { getEuroTextAmount } from '../../utils/helpers';

const DownloadLink = styled(Txt)`
	color: ${(p) => p.theme.palette.primary.main};
	font-size: 1.6rem;
	text-decoration: underline;
	text-decoration-color: ${(p) => p.theme.palette.primary.main};
	margin-top: 2rem;
`;

const Section = styled(SectionWrapper2).attrs({
	p: `2rem`,
	m: `0 0 2rem`,
	flexDirection: 'column',
	display: 'flex',
})``;

const CSV_HEADERS = [
	{ label: 'Cinema', key: 'cinema.name' },
	{ label: 'Anzahl der Abonnement-Tickets', key: 'numOfSubscriptionTickets' },
	{ label: 'Anzahl Tickets für Begleitpersonen', key: 'numAdditionalTickets' },
];

const columns = ['Kino', 'Anzahl der Abonnement-Tickets', 'Anzahl Tickets für Begleitpersonen'].map(
	(label, id) => ({
		label,
		id,
	})
);

type PayoutInfo = {
	numOfSubscriptionTickets: number;
	numAdditionalTickets: number;
	cinema: {
		id: string;
		name: string;
		city: string;
	};
};
const PayoutOverviewContent = ({
	payoutInfo,
	dateFrom,
	dateTo,
}: {
	payoutInfo: PayoutInfo[];
	dateFrom: string;
	dateTo: string;
}) => {
	const tableData = React.useMemo(
		() =>
			orderBy(
				payoutInfo
					? payoutInfo.map((p) => ({
							...p,
							cinema: {
								...p.cinema,
								name: `${p.cinema.name} - ${p.cinema.city}`,
							},
							numAdditionalTickets: p.numAdditionalTickets,
							id: p.cinema.id,

							rawData: p,
							data: [
								{
									text: `${p.cinema.name} - ${p.cinema.city}`,
								},
								{
									text: `${p.numOfSubscriptionTickets}`,
								},
								{
									text: p.numAdditionalTickets,
								},
							],
					  }))
					: [],
				['cinema'],
				['asc']
			),
		[payoutInfo]
	);
	return (
		<>
			<Table
				label={''}
				defaultRowsPerPage={5}
				columns={columns}
				allRows={tableData}
				isSelectable={false}
				isSearchable={false}
			/>
			<Box display="flex">
				<CSVLink
					data={tableData}
					filename={`Zahlungsübersicht_${dateFrom}_${dateTo}.csv`}
					target="_blank"
					headers={CSV_HEADERS}
					enclosingCharacter={''}
					separator={'\t'}
				>
					<DownloadLink>Download</DownloadLink>
				</CSVLink>
			</Box>
		</>
	);
};

const PayoutOverview = (): JSX.Element => {
	const [dateFrom, setDateFrom] = React.useState(format(addMonths(new Date(), -1), 'yyyy-MM-dd'));
	const [dateTo, setDateTo] = React.useState(format(new Date(), 'yyyy-MM-dd'));

	const { data, loading, error } = usePayoutInfo(dateFrom, dateTo);

	const handleSetStartDate = React.useCallback((newDate?: string) => {
		newDate && setDateFrom(newDate);
	}, []);

	const handleSetEndDate = React.useCallback((newDate?: string) => {
		newDate && setDateTo(newDate);
	}, []);

	return (
		<StickyHeaderWrapper label="Zahlungsübersicht" maxContentWidth="130rem">
			<DatetimeRangeSelectForm
				label="Zeitraum wählen"
				defaultStartDate={dateFrom}
				defaultEndDate={dateTo}
				onChangeStartDate={handleSetStartDate}
				onChangeEndDate={handleSetEndDate}
				dateOnly={true}
				hideCampaignEndTypeControls={true}
			/>
			<Section>
				<Txt variant="h6" fontWeight="bold" margin="2rem 0 0">
					ÜBERSICHT
				</Txt>
				{loading ? (
					<LoadingBox />
				) : error ? (
					<Txt color="error">{error.message}</Txt>
				) : (
					<PayoutOverviewContent payoutInfo={data} dateFrom={dateFrom} dateTo={dateTo} />
				)}
			</Section>
		</StickyHeaderWrapper>
	);
};

export default PayoutOverview;
