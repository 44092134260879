import React from 'react';
import styled from 'styled-components';
import { addMonths, format } from 'date-fns';
import { Box } from '@mui/material';

import SectionWrapper2 from '../../components/SectionWrapper2';
import Txt from '../../components/Txt';
import Image from '../../components/Image';
import ResponsiveBarChart from '../../components/ResponsiveBarChart';
import { SubscriptionStatistics, useSubscriptionStatistics } from '../../utils/statistics';
import LoadingBox from '../../components/LoadingBox';
import StickyHeaderWrapper from '../../components/StickyHeaderWrapper';
import { useSubscriptionTiers } from '../../utils/subscriptions';
import DatetimeRangeSelectForm from '../../components/DatetimeRangeSelectForm';

const Section = styled(SectionWrapper2).attrs({
	p: `2rem`,
	m: `0 0 2rem`,
})``;

const StyledCard = styled(Box)<{ flex?: string }>`
	border: 1px solid ${(p) => p.theme.customColors.grey};
	border-radius: 0.5rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	flex: ${(p) => p.flex ?? '1'};
	justify-content: space-between;
`;

const CardTitle = styled(Txt).attrs((p) => ({
	variant: 'h5',
	fontWeight: 'bold',
	m: p.m ?? '0 0 2rem',
}))``;

const SUBSCRIBERS_CHART_KEYS = ['value'];
const USER_VISITS_CHART_KEYS = ['value'];

const CinfinityStatsContent = ({
	subscriptionsStatistics,
}: {
	subscriptionsStatistics: SubscriptionStatistics;
}) => {
	const { data: subscriptionTiers, error, loading } = useSubscriptionTiers();
	const basicData = React.useMemo(() => {
		return [
			{
				id: 'numberOfUsers',
				label: 'Gesamtanzahl der Benutzer',
				value: subscriptionsStatistics?.numberOfUsers,
			},
			{
				id: 'numberOfActiveSubscriptions',
				label: 'Aktive Abonnements',
				value: subscriptionsStatistics?.numberOfActiveSubscriptions,
			},
			{
				id: 'numberOfSubscriptionTickets',
				label: 'Anzahl der Abonnement-Tickets',
				value: subscriptionsStatistics?.numberOfSubscriptionTickets,
			},
			{
				id: 'numberOfNewSubscriptions',
				label: 'Neue Abonnements',
				value: subscriptionsStatistics?.numberOfNewSubscriptions,
			},
			{
				id: 'canceledSubscriptions',
				label: 'Gekündigte Abonnements',
				value: subscriptionsStatistics?.canceledSubscriptions,
			},
		];
	}, [subscriptionsStatistics]);

	const frequencyData = React.useMemo(() => {
		return [
			{
				id: 'zero',
				label: '<1',
				value: subscriptionsStatistics?.visitFrequency.zero,
			},
			{
				id: 'one',
				label: '1',
				value: subscriptionsStatistics?.visitFrequency.one,
			},
			{
				id: 'two',
				label: '2',
				value: subscriptionsStatistics?.visitFrequency.two,
			},
			{
				id: 'three',
				label: '3',
				value: subscriptionsStatistics?.visitFrequency.three,
			},
			{
				id: 'four',
				label: '4',
				value: subscriptionsStatistics?.visitFrequency.four,
			},
			{
				id: 'five',
				label: '5',
				value: subscriptionsStatistics?.visitFrequency.five,
			},
			{
				id: 'sixToTen',
				label: '5-10',
				value: subscriptionsStatistics?.visitFrequency.sixToTen,
			},
			{
				id: 'elevenToTwenty',
				label: '10-20',
				value: subscriptionsStatistics?.visitFrequency.elevenToTwenty,
			},
			{
				id: 'twentyPlus',
				label: '20+',
				value: subscriptionsStatistics?.visitFrequency.twentyPlus,
			},
		];
	}, [subscriptionsStatistics?.visitFrequency]);

	const subscriptionData = React.useMemo(() => {
		if (!subscriptionTiers) return [];
		return subscriptionTiers.map((tier) => ({
			id: tier.id,
			name: tier.name,
			value:
				subscriptionsStatistics?.numberOfUsersPerTier.find((t) => t.tierName === tier.name)
					?.numActiveSubscribers ?? 0,
		}));
	}, [subscriptionTiers, subscriptionsStatistics?.numberOfUsersPerTier]);

	return (
		<>
			<Box display="flex" flexDirection="row" gap="2rem" m="0 0 2rem" flexWrap="wrap">
				{basicData.map((data) => (
					<StyledCard key={data.id} bgcolor="white" minWidth="40rem">
						<CardTitle>{data.label}</CardTitle>
						<Box display="flex" alignItems="flex-end" justifyContent="flex-end">
							<Txt variant="h4" fontWeight="bold" color="primary.main">
								{data.value}
							</Txt>
						</Box>
					</StyledCard>
				))}
			</Box>

			<Section bgcolor="white">
				<CardTitle>Nutzer pro Abo-Typ</CardTitle>
				<Box display="flex">
					{loading ? (
						<LoadingBox />
					) : error ? (
						<Txt color="error">{error.message}</Txt>
					) : (
						<>
							<Box display="flex" gap="2rem" flex="1 0 0%">
								<Box>
									{subscriptionData.map((tier) => (
										<Txt variant="h6" key={tier.id}>
											{tier.name}:
										</Txt>
									))}
								</Box>
								<Box>
									{subscriptionData.map((tier) => (
										<Txt variant="h6" key={tier.id}>
											{tier.value}
										</Txt>
									))}
								</Box>
							</Box>
							<Box height="50rem" display="flex" flex="3 0 0%">
								<ResponsiveBarChart
									data={subscriptionData}
									keys={SUBSCRIBERS_CHART_KEYS}
									indexBy="name"
									axisBottomLabel="Abonnement"
									axisLeftLabel="Anzahl der Nutzer"
								/>
							</Box>
						</>
					)}
				</Box>
			</Section>

			<Section>
				<CardTitle>Nutzer pro Besuchshäufigkeit</CardTitle>
				<Box height="50rem">
					<ResponsiveBarChart
						data={frequencyData}
						keys={USER_VISITS_CHART_KEYS}
						indexBy="label"
						axisBottomLabel="Anzahl der Besuche"
						axisLeftLabel="Anzahl der Nutzer"
					/>
				</Box>
			</Section>

			<Section flexShrink={1}>
				<CardTitle>Besuche pro Film</CardTitle>
				{subscriptionsStatistics.seenMovies?.length ? (
					<Box display="flex" gap="4rem" overflow="auto" p="2rem">
						{subscriptionsStatistics.seenMovies.map(({ movie, numVisits }) => (
							<StyledCard justifyContent="center" alignItems="center" key={movie.title} flex="0">
								<Box width="16rem" justifyContent="center" display="flex">
									<Image src={movie.poster} width="10rem" height="15rem" objectFit="cover" />
								</Box>
								<Txt fontWeight="bold" margin="1rem 0 0" align="center">
									{movie.title}
								</Txt>
								<Txt fontWeight="bold" variant="h6" color="primary.main">
									{numVisits.toLocaleString()}
								</Txt>
								<Txt variant="caption">Besuche</Txt>
							</StyledCard>
						))}
					</Box>
				) : (
					<Txt>Keine Daten im gewählten Zeitraum</Txt>
				)}
			</Section>
		</>
	);
};
const CinfinityStats = (): JSX.Element => {
	const [dateFrom, setDateFrom] = React.useState(format(addMonths(new Date(), -1), 'yyyy-MM-dd'));
	const [dateTo, setDateTo] = React.useState(format(new Date(), 'yyyy-MM-dd'));

	const { data, error, loading } = useSubscriptionStatistics(dateFrom, dateTo);

	const handleSetStartDate = React.useCallback((newDate?: string) => {
		newDate && setDateFrom(newDate);
	}, []);

	const handleSetEndDate = React.useCallback((newDate?: string) => {
		newDate && setDateTo(newDate);
	}, []);

	return (
		<StickyHeaderWrapper label="Cinfinity Statistiken" maxContentWidth="130rem">
			<DatetimeRangeSelectForm
				label="Zeitraum wählen"
				defaultStartDate={dateFrom}
				defaultEndDate={dateTo}
				onChangeStartDate={handleSetStartDate}
				onChangeEndDate={handleSetEndDate}
				dateOnly={true}
				hideCampaignEndTypeControls={true}
			/>
			{loading ? (
				<LoadingBox />
			) : error ? (
				<Txt color="error">{error.message}</Txt>
			) : (
				<CinfinityStatsContent subscriptionsStatistics={data} />
			)}
		</StickyHeaderWrapper>
	);
};

export default CinfinityStats;
